<template>
  <div>
    <div class="row mb10 relative swither-box" v-show="oppositeEcommerceProduct()">
      <div class="row absolute text-box">
        <div class="col-xs-6 center-xs pointer flex middle-xs word-nowrap" :class="{ 'cl-white' : currentRelatedTab }">
          <button
            class="center-xs middle-xs h-100 w-100 flex no-outline uppercase brdr-none weight-100 cl-white fs16 no-background p0"
            @click="changeCurrentTab(true)"
          >
            od ręki 24h
          </button>
        </div>
        <div class="col-xs-6 center-xs pointer flex middle-xs word-nowrap" :class="{ 'cl-white' : !currentRelatedTab }">
          <button
            class="center-xs middle-xs h-100 w-100 flex no-outline uppercase brdr-none weight-100 cl-white fs16 no-background"
            @click="changeCurrentTab(false)"
          >
            na zamówienie
          </button>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="switch">
          <span class="slider round" :class="{'active' : currentRelatedTab }" />
        </div>
      </div>
    </div>
    <section
      class="new-collection container"
      v-show="filterByEcommerce.length"
    >
      <div class="row start-xs">
        <div class="row py5">
          <div v-for="(relatedProduct, index) in filterByEcommerce" :key="index" class="mr10 col-xs-12 pl0 mb5">
            <router-link
              :event="relatedProduct.parent_id !== currentProduct.parent_id ? 'click' : 'none'"
              :to="productLink(relatedProduct)"
              class="no-underline pointer"
            >
              <span class="product-label relative ml40" :class="{ 'active' : relatedProduct.parent_id === currentProduct.parent_id }">{{ nameDiff(relatedProduct.name) }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getThumbnailPath as _thumbnailHelper, getValueFor } from '@vue-storefront/core/helpers'
// import ProductListing from 'theme/components/core/ProductListing'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import store from '@vue-storefront/core/store'
import config from 'config'

export default {
  name: 'RelatedSmall',
  data () {
    return {
      noEcommerceProduct: [],
      ecommerceProduct: []
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    currentProduct: {
      type: Object,
      required: true
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-load', this.refreshList)

    if (store.state.config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.refreshList)
      this.$bus.$on('user-after-logout', this.refreshList)
    }

    this.refreshList()
  },
  beforeDestroy () {
    if (store.state.config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.refreshList)
      this.$bus.$off('user-after-logout', this.refreshList)
    }
    this.$store.dispatch('ui/changeCurrentTab', true)
  },
  destroyed () {
    this.$bus.$off('product-after-load', this.refreshList)
  },
  methods: {
    changeCurrentTab (clickedTab) {
      if (typeof clickedTab === 'boolean') {
        if (!clickedTab) {
          if (!this.noEcommerceProduct.length) return
          let redirectTo = this.noEcommerceProduct.find(prod => prod.name === this.currentProduct.name)
          if (!redirectTo) redirectTo = this.noEcommerceProduct[0]
          if (redirectTo && (redirectTo.parent_id !== this.currentProduct.parent_id)) {
            this.$router.push(this.productLink(redirectTo))
          }
        } else {
          if (!this.ecommerceProduct.length) return
          let redirectTo = this.ecommerceProduct.find(prod => prod.name === this.currentProduct.name)
          if (!redirectTo) redirectTo = this.ecommerceProduct[0]
          if (redirectTo && (redirectTo.parent_id !== this.currentProduct.parent_id)) {
            this.$router.push(this.productLink(redirectTo))
          }
        }
        this.$store.dispatch('ui/changeCurrentTab', clickedTab)
      } else {
        let isOnEcommerce = !!this.ecommerceProduct.find((prod) => {
          return prod.id === this.currentProduct.parent_id || prod.parent_id === this.currentProduct.parent_id
        })
        let isOnNoEcommerce = !!this.noEcommerceProduct.find((prod) => {
          return prod.id === this.currentProduct.parent_id || prod.parent_id === this.currentProduct.parent_id
        })
        if ((isOnNoEcommerce && !isOnEcommerce) || !this.currentRelatedTab) {
          this.$store.dispatch('ui/changeCurrentTab', false)
        } else {
          this.$store.dispatch('ui/changeCurrentTab', true)
        }
      }
    },
    allProducts () {
      this.ecommerceProduct = []
      this.noEcommerceProduct = []
      if (this.product.related[this.type] && this.product.related[this.type].length) {
        let products = [...this.product.related[this.type], this.currentProduct]
        for (let product of products) {
          let childEcomm = product.configurable_children.filter(element => {
            if ((product.ecommerce === this.valueForYes && element.stock.qty > 0) || (product.ecommerce === this.valueForBoth && element.stock.qty > 0)) {
              return element
            }
          })
          let childNoEcomm = product.configurable_children.filter(element => {
            if (product.ecommerce === this.valueForNo || product.ecommerce === this.valueForBoth) {
              return element
            }
          })
          if (childEcomm.length) {
            this.ecommerceProduct.push(product)
          }
          if (childNoEcomm.length) {
            this.noEcommerceProduct.push(product)
          }
        }
        if (this.noEcommerceProduct.length) {
          this.noEcommerceProduct.sort((a, b) => {
            if (a.parent_id < b.parent_id) { return -1 }
            if (a.parent_id > b.parent_id) { return 1 }
            return 0
          })
        }
        if (this.ecommerceProduct.length) {
          this.ecommerceProduct.sort((a, b) => {
            if (a.parent_id < b.parent_id) { return -1 }
            if (a.parent_id > b.parent_id) { return 1 }
            return 0
          })
        }
      }
    },
    nameDiff (name) {
      let allNames = this.filterByEcommerce.map(prod => new Set(prod.name.split(' ')))
      let intersect = allNames.length ? allNames.slice(1).reduce(
        (memo, current) => {
          memo.forEach(
            (inner) => !current.has(inner) ? memo.delete(inner) : null
          );
          return memo
        },
        allNames[0]
      ) : null
      if (!intersect.size) return name

      let nameSet = new Set(name.trim().split(' '))
      intersect.forEach(el => nameSet.delete(el))
      if (!nameSet.size) return 'Lakierowany'

      name = [...nameSet].join(' ')

      if (name.includes('Polerowany')) {
        return 'Inox Polerowany'
      } else if (name.includes('Szlifowany')) {
        return 'Inox Szlifowany'
      } else {
        return name
      }
    },
    oppositeEcommerceProduct () {
      return !!(this.ecommerceProduct.length && this.noEcommerceProduct.length)
    },
    productLink (product) {
      return formatProductLink(product, currentStoreView().storeCode)
    },
    getThumbnail (i) {
      return _thumbnailHelper(i, 120, 120)
    },
    refreshList () {
      let sku = this.productLinks ? this.productLinks
        .filter(pl => pl.link_type === this.type)
        .map(pl => pl.linked_product_sku) : null

      let key = 'sku'

      if (sku) {
        let relatedProductsQuery = prepareRelatedQuery(key, sku)
        this.$store.dispatch('product/list', {
          query: relatedProductsQuery,
          size: 8,
          prefetchGroupProducts: false,
          updateState: false,
          includeFields: config.entities.productRelated.includeFields,
          excludeFields: config.entities.productRelated.excludeFields
        }).then((response) => {
          if (response) {
            this.$store.dispatch('product/related', {
              key: this.type,
              items: response.items
            }).then(() => {
              this.allProducts()
              this.changeCurrentTab()
              this.$forceUpdate()
            })
          } else {
            this.$store.dispatch('ui/changeCurrentTab', false)
          }
        })
      }
    }
  },
  computed: {
    valueForYes () {
      return getValueFor('ecommerce', 'yes')
    },
    valueForBoth () {
      return getValueFor('ecommerce', 'both')
    },
    valueForNo () {
      return getValueFor('ecommerce', 'no')
    },
    currentRelatedTab () {
      return this.$store.state.ui.currentTab
    },
    filterByEcommerce () {
      return this.currentRelatedTab
        ? this.ecommerceProduct
        : this.noEcommerceProduct
    },
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    product () {
      return this.$store.state.product
    },
    productLinks () {
      return this.product.current.product_links
    }
  }
}
</script>

<style lang="scss" scoped>

.product-label {
  &:before {
    position: absolute;
    content: '';
    width: 14px;
    left: -30px;
    top: 1px;
    border: 1px solid #041D59;
    height: 14px;
  }
  &:hover {
    &:after {
      position: absolute;
      content: '';
      background-color: #041D59;
      width: 8px;
      left: -27px;
      top: 4px;
      border: 1px solid #041D59;
      height: 8px;
    }
  }
  &.active {
    &:after {
      position: absolute;
      content: '';
      background-color: #041D59;
      width: 8px;
      left: -27px;
      top: 4px;
      border: 1px solid #041D59;
      height: 8px;
    }
  }
}
.no-background {
  background: none !important;
}
.swither-box {
  .switch {
    width: 100%;
    border-radius: 50px;
    background-color: #d9d9d9;
    transition: .4s;
    transition: left 0.2s ease-in-out;
    .slider {
      transition: .4s;
      transition: left 0.2s ease-in-out;
      width: 50%;
      height: 30px;
      border-radius: 50px;
      background-color: #7DBA10;
      display: flex;
      margin-left: auto;
      &.active {
        margin-left: 0;
      }
    }
  }
  .text-box {
    left: 18px;
    right: 18px;
    top: 0;
    bottom: 0;
  }
}
</style>
